/* https://github.com/pierreburel/sass-rem/blob/master/_rem.scss*/
/* paths */
/* https://responsivedesign.is/develop/getting-started-with-sass-and-breakpoint-mixin/ */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/* fonts */
/* roboto-300 - latin */
/*@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
       url('../fonts/roboto-v18-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
/*}
/* roboto-regular - latin */
/*@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
       url('../fonts/roboto-v18-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
/*}*/
/* roboto-500 - latin */
/*@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('../fonts/roboto-v18-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
/*}*/
@font-face {
  font-family: "Roboto Regular";
  src: url('/assets/fonts/roboto-v18-latin-regular.woff2?1510386191') format('woff2'), url('/assets/fonts/roboto-v18-latin-regular.woff?1510386191') format('woff');
}

@font-face {
  font-family: "Roboto Light";
  src: url('/assets/fonts/roboto-v18-latin-300.woff2?1510386190') format('woff2'), url('/assets/fonts/roboto-v18-latin-300.woff?1510386190') format('woff');
}

@font-face {
  font-family: "Roboto Medium";
  src: url('/assets/fonts/roboto-v18-latin-500.woff2?1510386190') format('woff2'), url('/assets/fonts/roboto-v18-latin-500.woff?1510386190') format('woff');
}

@font-face {
  font-family: "Roboto Bold";
  src: url('/assets/fonts/roboto-v18-latin-500.woff2?1510386190') format('woff2'), url('/assets/fonts/roboto-v18-latin-500.woff?1510386190') format('woff');
}

/**** page partial ****/
/* globale layout styles */
html {
  font-size: 100%;
  font-size: 16px;
}

body {
  font-size: 1rem;
  color: #364356;
  font-family: "Roboto Regular";
  padding: 0 40px;
}

/* globale container styles */
.outerContainer {
  margin-bottom: 2.5rem;
}

.outerContainer > .row {
  margin-left: 0;
  margin-right: 0;
}

.containerTop {
  margin-top: 3.125rem;
}

.containerTop > .row {
  margin-left: 0;
  margin-right: 0;
}

h1, h2 {
  font-family: "Roboto Bold";
  margin-bottom: 0.9375rem;
  font-size: 1.875rem;
  color: #283245;
}

h1 {
  font-size: 1.875rem;
  margin-bottom: 0.9375rem;
  font-weight: 600;
}

h2 {
  font-size: 1.25rem;
  margin-bottom: 0.625rem;
}

.row.top {
  justify-content: flex-start;
  margin-top: 1.875rem;
}

.row.top .i_blaetterlinks {
  margin-left: auto;
}

.row.top .i_blaetterrechts {
  margin-left: 0.625rem;
}

.row.top .i_edit {
  margin-left: 1.875rem;
}

.row.buttonline {
  justify-content: flex-end;
  margin-top: 1.25rem;
}

.row.search {
  align-items: baseline;
  justify-content: space-between;
}

.row .buttonline {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

/**** gui partial ****/
/* gui elements */
/* Buttons */
.btn {
  font-family: "Roboto Medium";
  color: #a4bbcd;
  background-color: transparent;
  font-variant: all-petite-caps;
  height: 2.3125rem;
  border: 1px solid #6489a6;
  -moz-border-radius: 1.25rem;
  -webkit-border-radius: 1.25rem;
  border-radius: 1.25rem;
  min-width: 9.375rem;
  margin-left: 1.25rem;
  padding: 0.3125rem 1.25rem;
  cursor: pointer;
  /* Suchenfeld */
}

.btn:hover {
  background-color: #e1e6ec;
  color: #fff;
}

.btn:not([disabled]):not(.disabled).active, .btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled):focus {
  color: #fff;
  background-color: #6489a6;
  border: 1px solid #6489a6;
  box-shadow: none;
  opacity: 1;
  transition: none;
}

.iconbutton, .btn.edit, .btn.download, .btn.stoerung {
  justify-content: flex-start;
  display: flex;
}

.iconbutton:before, .btn.edit:before, .btn.download:before, .btn.stoerung:before {
  content: '';
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.9375rem;
}

.btn.edit:before {
  background: url('/assets/Stift_einzeln.svg?1510570988') no-repeat;
}

.btn.download:before {
  background: url('/assets/Download.svg?1510570988') no-repeat;
}

.btn.stoerung:before {
  background: url('/assets/StoerungWhite.svg?1510570988') no-repeat;
}

.form-group.search {
  margin: 1.875rem 5rem 4.375rem 5rem;
  position: relative;
  display: flex;
  align-items: center;
}

.form-group.search.small {
  width: 12.5rem;
  opacity: .5;
  margin: 0;
}

.form-group.search.small input {
  padding-left: 1.875rem;
  padding-right: 3.125rem;
}

.form-group.search.small:after {
  right: 26px;
}

.form-group.search:after {
  content: '';
  position: absolute;
  right: 1.875rem;
  width: 1.25rem;
  height: 1.25rem;
  background: url('/assets/lupe.svg?1510579179') no-repeat;
}

.form-group.search .btn {
  margin: 0 auto;
  width: 100%;
  justify-content: flex-start;
  display: flex;
  padding-right: 3.75rem;
  padding-left: 1.875rem;
  font-size: 1.125rem;
  cursor: text;
  font-variant: inherit;
  text-align: left;
  height: 3.125rem;
  border: 2px solid #96b9cd;
  -moz-border-radius: 1.875rem;
  -webkit-border-radius: 1.875rem;
  border-radius: 1.875rem;
}

.form-group.search .btn.placeholder {
  color: #6489a6;
}

.form-group.search .btn:-moz-placeholder {
  color: #6489a6;
}

.form-group.search .btn::-webkit-input-placeholder {
  color: #6489a6;
}

.form-group.search .btn:hover {
  color: #364356;
  -moz-transition: all, 0.4s, ease-in;
  -o-transition: all, 0.4s, ease-in;
  -webkit-transition: all, 0.4s, ease-in;
  transition: all, 0.4s, ease-in;
}

.form-group.search .btn:not([disabled]):not(.disabled).active, .form-group.search .btn:not([disabled]):not(.disabled):active, .form-group.search .btn:not([disabled]):not(.disabled):focus {
  color: #364356;
  background-color: #fff;
  border: 2px solid #6489a6;
}

.btn-primary {
  color: #fff;
  background-color: #6489a6;
}

.btn-primary:hover {
  background-color: #6489a6;
  opacity: .6;
  -moz-box-shadow: #e1e6ec 0 2px 8px 1px;
  -webkit-box-shadow: #e1e6ec 0 2px 8px 1px;
  box-shadow: #e1e6ec 0 2px 8px 1px;
}

.btn-primary:not([disabled]):not(.disabled).active, .btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled):focus {
  background-color: #6489a6;
  border: 1px solid #6489a6;
  box-shadow: none;
  opacity: 1;
  transition: none;
}

/* inputs */
.outerContainer fieldset[disabled] .form-group input:hover {
  background-color: #fff;
}

.outerContainer fieldset .form-group input:hover {
  background-color: #f6f7fb;
}

input:focus, input.btn:focus, input.form-control:focus {
  -moz-box-shadow: #e1e6ec 0 2px 8px 1px;
  -webkit-box-shadow: #e1e6ec 0 2px 8px 1px;
  box-shadow: #e1e6ec 0 2px 8px 1px;
}

/* icons */
.icon, input[type="checkbox"]:checked + label span.chooseMixed, span.chooseMixed, input[type="checkbox"]:checked + label span.chooseAll, span.chooseAll, .customCheckbox, .back, .i_profile, .i_grid, .i_list, .i_sort_down, .i_heizung {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.icon_big, .selectDownload, .i_blaetterlinks, .i_blaetterrechts, .i_edit, .i_stoerung, .i_aufklapp {
  display: inline-block;
  width: 2.125rem;
  height: 2.125rem;
  cursor: pointer;
}

.icon_big:hover, .selectDownload:hover, .i_blaetterlinks:hover, .i_blaetterrechts:hover, .i_edit:hover, .i_stoerung:hover, .i_aufklapp:hover {
  opacity: .4;
}

.icon_large, .i_haken, .i_work, .i_attention, .i_attention_rot {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
}

.selectDownload {
  background: url('/assets/Copy.png?1510570985') no-repeat;
}

.selectDownload:hover {
  background: url('/assets/CopyActive.png?1510570985') no-repeat;
}

.active .selectDownload {
  background: url('/assets/CopyActive.png?1510570985') no-repeat;
}

input[type="checkbox"]:checked + label span.chooseMixed, span.chooseMixed {
  background: url('/assets/Checkbox_Multi.svg?1510386190') no-repeat;
}

input[type="checkbox"]:checked + label span.chooseAll, span.chooseAll {
  background: url('/assets/Haken_Active.svg?1510386190') no-repeat;
}

.customCheckbox {
  background: url('/assets/Checkbox.svg?1510386190') no-repeat;
}

.back {
  background: url('/assets/Back.svg?1510570986') no-repeat;
}

.i_profile {
  background: url('/assets/Profile.svg?1510570986') no-repeat;
}

.i_grid {
  background: url('/assets/GridviewGrey.svg?1510570987') no-repeat;
}

.i_list {
  background: url('/assets/ListviewActive.svg?1510570987') no-repeat;
}

.i_blaetterlinks {
  background: url('/assets/pfeil_links_kreis.svg?1510579179') no-repeat;
}

.i_blaetterrechts {
  background: url('/assets/pfeil_rechts_kreis.svg?1510579179') no-repeat;
}

.i_edit {
  background: url('/assets/bleistift_kreis.svg?1510579178') no-repeat;
}

.i_stoerung {
  background: url('/assets/StoerungWhite.svg?1510570988') no-repeat;
}

.i_aufklapp {
  background: url('/assets/pfeil_runter_kreis.svg?1510579179') no-repeat;
}

.i_sort_down {
  background: url('/assets/Pfeil_sort_down.svg?1510570986') no-repeat;
}

.i_heizung {
  width: 1.5rem;
  background: url('/assets/Heizung.svg?1510570988') no-repeat;
}

.i_haken {
  background: url('/assets/Ready.svg?1510570989') no-repeat;
  background-size: cover;
}

.i_work {
  background: url('/assets/Work.svg?1510570989') no-repeat;
  background-size: cover;
}

.i_attention {
  background: url('/assets/Attention2.svg?1510570989') no-repeat;
  background-size: cover;
}

.i_attention_rot {
  background: url('/assets/attention_rot.svg?1510570989') no-repeat;
  background-size: cover;
}

/* links */
a.backlink {
  display: flex;
  align-items: flex-start;
  color: #364356;
  padding-left: 0;
}

/* forms */
.form-group {
  border: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  margin-bottom: 2.25rem;
}

.form-group label {
  margin-bottom: 0.3125rem;
  margin-left: 0.9375rem;
  font-variant: all-petite-caps;
}

.form-control {
  border: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.form-control:focus {
  background-color: #f6f7fb;
  border: none;
  -moz-box-shadow: #e1e6ec 0 2px 8px 1px;
  -webkit-box-shadow: #e1e6ec 0 2px 8px 1px;
  box-shadow: #e1e6ec 0 2px 8px 1px;
  box-shadow: none;
}

/* helper */
span.accent {
  font-family: "Roboto Bold";
}

/*table */
/* http://inlehmansterms.net/2014/10/11/responsive-tables-with-flexbox/ */
.table {
  font-family: "Roboto Regular";
  color: #283245;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.table-header {
  margin: 15px 0;
  border: 1px solid #e1e6ec;
  background-color: #fff;
}

.table-header.table-row {
  margin-top: 30px;
  background-color: #fff;
}

.table-header .table-row-item > span {
  font-variant: all-petite-caps;
  font-size: 1.2rem;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
}

@media (min-width: 50em) {
  .table-header .table-row-item {
    font-variant: all-petite-caps;
    font-size: 1rem;
  }
}

.table-row {
  width: 100%;
  background-color: #f6f7fb;
  margin: 7px 0;
}

@media (min-width: 50em) {
  .table-row {
    display: flex;
    flex-flow: row nowrap;
  }
}

.table-header.table-row:hover {
  background-color: #fffc;
}

.table-row:hover {
  background-color: #e1e6ec;
}

.table-row.active {
  background-color: #e1e6ec;
}

.table-row-item {
  min-width: 5rem;
  font-family: "Roboto Bold";
  font-size: 0.875rem;
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.75rem 0.9375rem;
  word-break: keep-all;
  justify-content: space-between;
  align-items: center;
}

.immobilie_entry .table-row-item > span {
  white-space: nowrap;
}

.table-row-item.u-flex-grow0 {
  flex-grow: 0;
}

.table-row-item.u-flex-grow1 {
  flex-grow: 1;
}

.table-row-item.u-flex-grow3 {
  flex-grow: 3;
}

.table-row-item.u-flex-grow5 {
  flex-grow: 5;
}

.table-row-item.right {
  justify-content: flex-end;
}

.table-row-item:before {
  content: attr(data-header);
  width: 30%;
  font-weight: 700;
}

@media (min-width: 50em) {
  .table-row-item {
    padding: 0.75rem 0.9375rem;
  }

  .table-row-item:before {
    content: none;
  }
}

@media (min-width: 50em) {
  .download .table-header .table-row-item {
    background-color: #e1e6ec;
  }
}

/* checkboxes */
/* custom checkboxes: https://webdesign.tutsplus.com/de/articles/quick-tip-easy-css3-checkboxes-and-radio-buttons--webdesign-8953 */
/* Hide the browser's default checkbox */
input[type="checkbox"] {
  display: none;
}

.form-check input + label {
  display: flex;
  align-items: center;
  margin: 0;
}

input[type="checkbox"] + label span {
  display: inline-block;
  position: absolute;
  right: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 auto;
  vertical-align: middle;
  background: url('/assets/Checkbox.svg?1510386190') left top no-repeat;
  cursor: pointer;
}

input[type="checkbox"] + label span:hover {
  opacity: .4;
}

input[type="checkbox"]:checked + label span {
  background: url('/assets/Haken_Active.svg?1510386190') top no-repeat;
}

/**** animations partial ****/
/* Animationen: momentan auskommentiert */
@-webkit-keyframes 'sidebar_active' {
  0% {
    background-color: #f6f7fb;
  }
  99% {
    background-color: #f6f7fb;
  }
  100% {
    background-color: #e1e6ec;
  }
}

@-webkit-keyframes 'sidebar_active_before' {
  0% {
    width: 0;
  }
  99% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

@-webkit-keyframes 'sidebar_active_after' {
  0% {
    visibility: hidden;
  }
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

/**** start Komponenten-styles ****/
/**** start Komponenten-styles ****/
/**** start Komponenten-styles ****/
/* login */
/* https://github.com/pierreburel/sass-rem/blob/master/_rem.scss*/
/* paths */
/* https://responsivedesign.is/develop/getting-started-with-sass-and-breakpoint-mixin/ */
body {
  background-color: #f6f7fb;
}

.login.outerContainer {
  max-height: 100vh;
  max-width: 37.5rem;
  padding-top: 5em;
  margin: 11.25rem auto;
}

.login.outerContainer h1 {
  font-size: 1.5rem;
}

.loginContainer {
  background-color: #e1e6ec;
}

.loginContainer form {
  padding: 0.625rem 3.4375rem;
  border-bottom: 1px solid #fff;
}

.loginContainer form .form-group {
  padding-right: 0;
  padding-left: 0;
  flex-grow: 1;
}

.loginContainer form .form-group:first-child {
  border-right: 1px solid #e1e6ec;
}

.loginContainer form .form-group:first-child button {
  float: right;
}

.loginContainer form .row:last-child {
  justify-content: flex-end;
}

.loginContainer form .row:last-child button {
  float: right;
}

.loginContainer .loginAddition {
  padding: 0.625rem 3.4375rem;
}

.loginContainer .loginAddition.row {
  align-items: center;
}

.loginContainer .loginAddition p {
  margin: 0;
  margin-right: 0.9375rem;
}

/* layout: Elterncontainer -> aeussere container */
/* https://github.com/pierreburel/sass-rem/blob/master/_rem.scss*/
/* paths */
/* https://responsivedesign.is/develop/getting-started-with-sass-and-breakpoint-mixin/ */
body {
  background-color: #fff;
}

.container-fluid {
  padding: 1.875rem 2.8125rem 0 2.8125rem;
}

.outlet {
  padding: 0 3.4375rem;
}

/* sidebar */
/* https://github.com/pierreburel/sass-rem/blob/master/_rem.scss*/
/* paths */
/* https://responsivedesign.is/develop/getting-started-with-sass-and-breakpoint-mixin/ */
.sidebar {
  color: #000;
  min-width: 16.875rem;
  background-color: #f6f7fb;
  font-variant: all-petite-caps;
  font-size: 1.1875rem;
  font-family: "Roboto Regular";
  font-weight: 500;
  justify-content: flex-start;
}

.sidebar #navbarSupportedContent {
  width: 100%;
}

.sidebar.navbar {
  align-items: left;
  padding-right: 0;
  padding-left: 0;
}

.sidebar.navbar .nav-link {
  background-color: #f6f7fb;
  color: #000;
  height: 2.5rem;
  width: 100%;
  line-height: 1;
  padding-left: 2.5rem;
  margin-left: 0;
  position: relative;
}

.sidebar.navbar .nav-link.userLoggedIn {
  border-top: 2px solid #fff;
  display: flex;
  background-color: #e1e6ec;
}

.sidebar.navbar .nav-link.userLoggedIn:before {
  content: '';
  width: 1.25rem;
  height: 1.25rem;
}

.sidebar.navbar .nav-link:before {
  content: '';
  display: block;
  position: absolute;
  left: 0%;
  bottom: 0;
  width: 0px;
  height: 100%;
}

.sidebar.navbar .nav-link:hover {
  opacity: .4;
  cursor: pointer;
}

.sidebar.navbar .nav-link.active, .sidebar.navbar .nav-link.visited {
  background-color: #e1e6ec;
  font-family: "Roboto Bold";
  color: #000;
  animation-name: sidebar_active;
  animation-duration: .4s;
}

.sidebar.navbar .nav-link.active:after, .sidebar.navbar .nav-link.visited:after {
  content: '';
  display: block;
  position: relative;
  left: 106%;
  bottom: 29px;
  width: 0px;
  height: 0px;
  visibility: visible;
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  border-style: solid;
  border-width: 1.375rem 0 1.375rem 0.625rem;
  border-color: transparent transparent transparent #e1e6ec;
  animation-name: sidebar_active_after;
  animation-duration: .4s;
}

.sidebar.navbar .nav-link.active:before, .sidebar.navbar .nav-link.visited:before {
  content: '';
  display: block;
  position: absolute;
  left: 0%;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #e1e6ec;
}

.sidebar.navbar .nav-link.active:hover {
  opacity: 1;
  cursor: default;
}

.sidebar.navbar .nav-link.active:before {
  content: '';
  display: block;
  position: absolute;
  left: 0%;
  bottom: 0;
  width: 0px;
  height: 100%;
  animation-name: sidebar_active_before;
  animation-duration: .4s;
}

.sidebar .navbar-brand {
  margin-right: 0;
  width: 100%;
  height: 156px;
}

.sidebar .navbar-collapse {
  flex-flow: column;
}

.sidebar .i_profile {
  margin-left: -1.25rem;
  margin-right: 0.625rem;
}

.sidebar .banner {
  height: auto;
  width: 70%;
  margin: 1.5625rem 15%;
}

.sidebar .banner_sub {
  position: absolute;
  top: 95px;
  left: 52px;
}

/* portfolio */
/* https://github.com/pierreburel/sass-rem/blob/master/_rem.scss*/
/* paths */
/* https://responsivedesign.is/develop/getting-started-with-sass-and-breakpoint-mixin/ */
.portfolio .row.top p {
  margin-left: auto;
}

.portfolio .row.top span.i_grid, .portfolio .row.top span.i_list {
  margin-left: 0.625rem;
}

.portfolio .row.top .accent {
  margin-right: 1.25rem;
}

.portfolio .monteur_list .immobilie_entry.header {
  border: 1px solid #e1e6ec;
  margin-top: 40px;
  background-color: #fff;
}

.portfolio .monteur_list .immobilie_entry.header span {
  font-size: 0.875rem;
  font-family: "Roboto Bold";
}

.portfolio .monteur_list .immobilie_entry.header span:last-child {
  margin-left: auto;
  width: 15%;
}

.portfolio .monteur_list .immobilie_entry.header:hover {
  outline: none;
}

.portfolio .monteur_list .immobilie_entry span:last-child {
  margin-left: auto;
  width: 15%;
}

.portfolio .immobilien_list, .portfolio .monteur_list {
  flex-direction: column;
}

.portfolio .immobilien_list .immobilie_entry, .portfolio .monteur_list .immobilie_entry {
  height: 5rem;
  background-color: #f6f7fb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.portfolio .immobilien_list .immobilie_entry > span, .portfolio .immobilien_list .immobilie_entry .immobilie_address, .portfolio .monteur_list .immobilie_entry > span, .portfolio .monteur_list .immobilie_entry .immobilie_address {
  margin-left: 0.9375rem;

}

.portfolio .immobilien_list .immobilie_entry .immobilie_image img, .portfolio .monteur_list .immobilie_entry .immobilie_image img {
  width: 5rem;
  height: 5rem;
}

.portfolio .immobilien_list .immobilie_entry .immobilie_address, .portfolio .monteur_list .immobilie_entry .immobilie_address {
  font-size: 0.875rem;
  width: 40%;
}

.portfolio .immobilien_list .immobilie_entry .immobilie_address p, .portfolio .monteur_list .immobilie_entry .immobilie_address p {
  margin: 0;
}

.portfolio .immobilien_list .immobilie_entry .immobilie_number, .portfolio .monteur_list .immobilie_entry .immobilie_number {
  font-size: 1.5rem;
  font-family: "Roboto Bold";
  width: 20%;
}

.portfolio .immobilien_list .immobilie_entry .immobilie_we, .portfolio .monteur_list .immobilie_entry .immobilie_we {
  font-size: 1.5rem;
  font-family: "Roboto Light";
  width: 10%;
}

.portfolio .immobilien_list .immobilie_entry:hover, .portfolio .monteur_list .immobilie_entry:hover {
  outline: 3px solid #e1e6ec;
}

.portfolio .immobilien_list .immobilie_entry.active, .portfolio .monteur_list .immobilie_entry.active {
  outline: 3px solid #e1e6ec;
  background-color: #e1e6ec;
}

/* nutzeinheitenUebersicht */
/* https://github.com/pierreburel/sass-rem/blob/master/_rem.scss*/
/* paths */
/* https://responsivedesign.is/develop/getting-started-with-sass-and-breakpoint-mixin/ */
.wohnung_detail, .immobilie_detail {
  background-color: #e1e6ec;
  margin-left: 0;
  margin-right: 0;
  margin-top: 15px;
}

.wohnung_detail .immobilie_image, .immobilie_detail .immobilie_image {
  padding-left: 0px;
}

.wohnung_detail .form-group, .immobilie_detail .form-group {
  margin-right: 1.25rem;
}

.wohnung_detail .form-group.narrow, .immobilie_detail .form-group.narrow {
  margin-right: 0.0625rem;
  flex-shrink: 0;
}

.wohnung_detail .form-group input, .immobilie_detail .form-group input {
  /* text in den inputs ueber placeholder setzen */
  font-family: "Roboto Bold";
  font-size: 0.875rem;
  background-color: #fff;
}

.wohnung_detail .form-group input.placeholder, .immobilie_detail .form-group input.placeholder {
  color: #364356;
}

.wohnung_detail .form-group input:-moz-placeholder, .immobilie_detail .form-group input:-moz-placeholder {
  color: #364356;
}

.wohnung_detail .form-group input::-webkit-input-placeholder, .immobilie_detail .form-group input::-webkit-input-placeholder {
  color: #364356;
}

.wohnung_detail .form-group #PLZ, .immobilie_detail .form-group #PLZ {
  min-width: 4.0625rem;
}

.status_info {
  font-size: 0.875rem;
  min-height: 4.875rem;
  background-color: #e1e6ec;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  padding: 1.25rem;
}

.status_info .row {
  justify-content: flex-start;
  align-items: center;
  width: 93%;
  background-color: #fff;
  margin: 0;
}

.status_info .i_aufklapp {
  margin-left: auto;
}

.status_info p {
  margin: 0;
  min-height: 2.375rem;
  line-height: 2.375rem;
  margin-left: 0.9375rem;
}

.status_info p.date {
  width: 6.875rem;
  border-right: 5px solid red;
}

.status_info p.date:after {
  background: none;
}

/* dl bezeichnet die Teile, die nur bei aktuelle Messwerte sichtbar sein sollen
(top-Markerklasse = dl)*/
.wohneinheit {
  /* Normalzustand */
  /* aktuelle Messwerte-Zustand */
}

.wohneinheit .dl {
  display: none;
}

.wohneinheit .table-row-item > span {
  display: inline-block;
}

.wohneinheit .top, .wohneinheit .buttonline {
  width: 100%;
}

.wohneinheit .select {
  justify-content: center;
}

.wohneinheit.download .table-row-item > span, .wohneinheit.download .buttonline {
  display: none;
}

.wohneinheit.download .dl, .wohneinheit.download .table-row-item {
  display: flex;
}

.wohneinheit.download .wohneinheiten_table {
  padding: 1.875rem 1.875rem 2.5rem 1.875rem;
  -moz-box-shadow: #ccc 0 2px 8px 5px;
  -webkit-box-shadow: #ccc 0 2px 8px 5px;
  box-shadow: #ccc 0 2px 8px 5px;
}

.wohneinheit.download .filterDownloads {
  width: 100%;
  min-height: 4.375rem;
  background-color: #f6f7fb;
  margin-bottom: 1rem;
  /*.date {
      flex-basis: rem(110px);
  }*/
}

.wohneinheit.download .filterDownloads .date_input, .wohneinheit.download .filterDownloads .filter_input {
  display: flex;
  align-items: center;
}

.wohneinheit.download .filterDownloads .date_input {
  justify-content: space-between;
}

.wohneinheit.download .filterDownloads .date_input input {
  margin: 0 0.625rem;
}

.wohneinheit.download .filterDownloads .filter_input {
  justify-content: flex-end;
}

.wohneinheit.download .filterDownloads .filter_input > span {
  margin-left: 1.25rem;
}

.wohneinheit.download .filterDownloads .form-group {
  margin: 0;
}

.wohneinheit.download .table-row-item.narrow {
  min-width: auto;
}

.wohneinheit.download .select {
  display: none;
}

.wohneinheit.download .form-check {
  margin: 0;
  padding: 0;
  width: 100%;
}

/* geraeteUebersicht */
/* https://github.com/pierreburel/sass-rem/blob/master/_rem.scss*/
/* paths */
/* https://responsivedesign.is/develop/getting-started-with-sass-and-breakpoint-mixin/ */
.geraeteuebersicht .table-row .table-row-item:first-child {
  justify-content: flex-start;
}

.geraeteuebersicht .form-check {
  width: 1.5rem;
  margin: 0;
  margin-right: 1.25rem;
}

.geraeteuebersicht .row.top .i_grid {
  margin-left: auto;
}

.geraeteuebersicht .row.top span {
  margin-left: 0.625rem;
}

/* dashboard */
.with_icons .status_info {
  padding: 0;
}

.with_icons .status_info > .row {
  width: 100%;
  padding: 1.25rem;
}

.with_icons .status_info p {
  color: #fff;
  font-size: 1.875rem;
  font-family: "Roboto Light";
  width: 100%;
  height: 3.125rem;
  background-color: #f6cb00;
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.with_icons .status_info p:first-child {
  background-color: #9bb659;
  height: 7.5rem;
}

.with_icons .status_info p:last-child {
  background-color: #ce2d01;
}

.with_icons .status_info p span {
  width: 2.5rem;
  height: 2.5rem;
}

.i_readings {
  background: url(assets/messprofil.svg) no-repeat;
  background-size: cover;
  height: 2.5rem;
  width: 2.5rem;
  display: inline-block;
  opacity: .7;
}

.i_readings:hover {
  cursor: pointer;
}

